export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71')
];

export const server_loads = [0,14,2,4,8,9,16,10];

export const dictionary = {
		"/(landingpage)": [~40,[14]],
		"/(landingpage)/(pages)/Tutorial": [41,[14]],
		"/(landingpage)/(pages)/aviso_privacidad": [42,[14]],
		"/(landingpage)/(pages)/blog/[post]/[id]": [~43,[14]],
		"/(landingpage)/(pages)/brokers": [44,[14]],
		"/(landingpage)/(pages)/catalogo_de_contratos": [45,[14,15]],
		"/(landingpage)/(pages)/catalogo_de_contratos/prueba_concepto_templates_dinamicos": [47,[14,15]],
		"/(landingpage)/(pages)/catalogo_de_contratos/[templateId]": [46,[14,15]],
		"/(app)/documentos": [~17,[2,3]],
		"/(app)/documentos/documento/crear": [~25,[2,3]],
		"/(app)/documentos/documento/crear/crear_con_template_acuerdo": [~26,[2,3]],
		"/(app)/documentos/documento/crear/crear_con_template_acuerdo/[templateId]": [~27,[2,3]],
		"/(app)/documentos/documento/crear/crear_documento_subiendo_pdf": [~28,[2,3]],
		"/(app)/documentos/documento/crear/crear_documento_vacio": [~29,[2,3]],
		"/(app)/documentos/documento/[documentId]/v1/esperando_firmas": [~20,[5,6]],
		"/(app)/documentos/documento/[documentId]/v1/firmando_pdf": [~21,[5,7]],
		"/(app)/documentos/documento/[documentId]/v1/recolectando_firmantes": [~22,[5]],
		"/(app)/documentos/documento/[documentId]/v1/sellos_pdf": [~23,[5]],
		"/(app)/documentos/documento/[documentId]/v1/todos_firmaron": [~24,[5]],
		"/(app)/documentos/documento/[documentId]/[deprecatedUrls]": [~19,[2,3,4]],
		"/(app)/documentos/template/[documentId]/edit": [30,[8]],
		"/(app)/documentos/[carpeta]": [18,[2,3]],
		"/(landingpage)/(pages)/features/firma_autografa": [48,[14]],
		"/(landingpage)/(pages)/features/firma_avanzada": [49,[14]],
		"/(landingpage)/(pages)/features/firma_digital_documentos": [50,[14]],
		"/(landingpage)/(pages)/features/gestion_documentos": [51,[14]],
		"/(landingpage)/(pages)/features/live_check": [52,[14]],
		"/(landingpage)/(pages)/features/nom_151": [53,[14]],
		"/(landingpage)/(pages)/features/registro_marca": [54,[14]],
		"/(landingpage)/(pages)/features/registros_auditoria": [55,[14]],
		"/(landingpage)/(pages)/features/sellos_tiempo_digital": [56,[14]],
		"/(landingpage)/(pages)/industrias/legal_notarias": [~57,[14]],
		"/(app)/onboarding": [31,[9]],
		"/(landingpage)/(pages)/precios": [58,[14,16]],
		"/(landingpage)/(pages)/precios/checkout": [59,[14,16]],
		"/(landingpage)/(pages)/precios/checkout/gracias/[membresia]": [60,[14,16]],
		"/(landingpage)/(pages)/prelanzamiento": [61,[14]],
		"/(landingpage)/(pages)/programa_referidos_para_ti": [63,[14]],
		"/(landingpage)/(pages)/programa_referidos": [62,[14]],
		"/(app)/referidos/admin": [32,[2,10]],
		"/(app)/referidos/felicidades": [33,[2,10]],
		"/(landingpage)/(pages)/registrate": [64,[14]],
		"/(landingpage)/(pages)/seguridad": [65,[14]],
		"/(app)/settings/facturacion": [34,[2,11,12]],
		"/(app)/settings/membresia": [35,[2,11,13]],
		"/(app)/settings/membresia/documentos_terminados": [36,[2,11,13]],
		"/(app)/settings/membresia/gracias/[membresia]": [37,[2]],
		"/(app)/settings/membresia/membresia_vencida": [38,[2,11,13]],
		"/(app)/settings/mi_cuenta": [39,[2,11]],
		"/(landingpage)/(pages)/signin": [66,[14]],
		"/(landingpage)/(pages)/signin/adminauth": [67,[14]],
		"/(landingpage)/(pages)/signin/correo_enviado": [68,[14]],
		"/(landingpage)/(pages)/signin/error": [~69,[14]],
		"/(landingpage)/(pages)/terminos_condiciones": [70,[14]],
		"/(landingpage)/(pages)/validador_documentos_pdf_firmas_digitales_allsign": [71,[14]]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';